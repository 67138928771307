<template>
  <v-container class="pa-0"  style="max-width: 100%" fill-height tag="section">

      <v-row class="pa-0" style="height:100%" >
        <v-col cols="12" md="6" lg="6" class="pa-0"  align="center">
          <v-card flat min-width="100%" style="height: 100%;margin-bottom:120px;">
            <v-row
              no-gutters
              height="100%"
              style="
                min-height: 80%;
                max-width: 600px;
                min-width: 200px;
                height: 80%;
              "
              class="mx-auto"
            >
              <v-col align-self="center" align="center" class="pa-0" >
                <v-card-text
                  dir="auto"
                  class="text-center"
                  style="width: 100%; text-align: center"
                >
                  <img
                    contain
                    width="180px"
                    style="margin: auto"
                    src="/static/unvIcon.png"
                    @error="errorImageHandler"
                  />
                </v-card-text>

                <v-form v-on:submit.prevent="login" class="px-10">
                  <v-card-text class="text-center">
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            icon
                            @click="langChang()"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon large color="light-blue darken-1"
                              >mdi-translate</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ $t("setting.lang") }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="reColor()"
                          >
                            <v-icon large color="amber"
                              >mdi-theme-light-dark</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ $t("setting.darkMode") }}</span>
                      </v-tooltip>
                    </div>
                    <!-- <div class="text-center grey--text body-1 font-weight-light">UIMS</div> -->
                    <v-text-field
                      :label="$t('login.username')"
                      v-model="username"
                      :rules="[(value) => !!value || $t('ThisFieldIsRequired')]"
                      outlined
                      placeholder=" "
                      persistent-placeholder
                    />
                    <v-text-field  autocomplete="current-password webauthn"
                      placeholder=" "
                      persistent-placeholder
                      :label="$t('login.password')"
                      v-model="password"
                      :rules="[(value) => !!value || $t('ThisFieldIsRequired')]"
                      :type="show1 ? 'text' : 'password'"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      outlined
                    />
                  </v-card-text>
                  <v-col class="text-center">
                    <v-btn
                      :loading="loading"
                      @click.prevent="login()"
                      type="submit"
                      large
                      block
                      color="primary darken-1"
                      ref="sendReply"
                    >
                      {{ $t("login.signIn") }}
                    </v-btn>
                  </v-col>
                </v-form>
              </v-col>
               <v-col cols="12" align="center" v-if="unvInfo && unvInfo.univName">
                <h3 class="mb-1 text-wrap">
                  {{
                    $i18n.locale == "ar" ? unvInfo.univName : unvInfo.univNameEN
                  }}
                </h3>
                <h3 class="mb-1 text-wrap" v-if="unvInfo && unvInfo.clientVersion">
                  {{ $t("version") }} {{ unvInfo.clientVersion }}
                </h3>
              </v-col>

            </v-row>

          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="6"
          align-self="center"
          class="hidden-xs-and-up hidden-sm-and-down"
          style="text-align: -webkit-center"
        >
          <v-img
            max-width="70%"
            src="../../assets/student-background.svg"
          ></v-img>
        </v-col>
      </v-row>
        <v-col cols="12" md="12" lg="12" class="pa-0">
          <v-footer absolute dark padless color="grey darken-3">
              <v-card class="flex" flat tile>
              <v-card-title class="grey darken-3">
                <strong class="subheading">
                  <a text href="https://kf.iq" target="_blank">
                    <v-img
                      width="200"
                      src="../../assets/Kafel-logo.svg"
                      lazy-src="../../assets/Kafel-logo.svg"
                      style="width: 200px; height: 52px"
                    >
                    </v-img>
                  </a>
                </strong>
                <v-spacer></v-spacer>

                <v-btn
                  v-for="icon in icons"
                  :key="icon.icon"
                  :href="icon.link"
                  target="_blank"
                  class="mx-4"
                  dark
                  icon
                >
                  <v-icon size="24px">
                    {{ icon.icon }}
                  </v-icon>
                </v-btn>
              </v-card-title>
            </v-card>
          </v-footer>

        </v-col>


  </v-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

import moment from "moment";

export default {
  data: () => ({
    show1: false,
    rules: {
      required: (value) => !!value || this.$t("login.ThisFieldIsRequired"),
      min: (v) => v.length >= 6 || this.$t("login.PasswordMustContain6OrMoreElements"),
    },
    username: "",
    password: "",
    loading: false,
    rtl: localStorage.rtl == "true",
    dark: localStorage.dark == "true",
    icons: [
      {
        icon: "mdi-instagram",
        link: "https://instagram.com/alkafeel.center/",
      },
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/alkafeelcenter/",
      },
    ],
  }),
  computed: {
    unvInfo() {
      return JSON.parse(localStorage.getItem("unvInfo"));
    },
  },
  mounted() {
    // this.$vuetify.rtl = this.rtl;
    // this.$vuetify.theme.dark = this.dark;
    // var lang = this.rtl ? "ar" : "en";
    // localStorage.lang = lang;
    // this.$i18n.locale = lang;
  },
  methods: {
    errorImageHandler() {
      // this.univPictureUrl= require('../../assets/logo2.svg');
      // this.$forceUpdate()
    },
    reColor() {
      // this.$router.go(0);
      //   console.log(this.$vuetify.theme.dark);
      localStorage.setItem("darkMode", !this.$vuetify.theme.dark);
      location.reload();
    },
    langChang() {
      this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar";
      localStorage.setItem("lang", this.$i18n.locale);
      this.$vuetify.rtl = localStorage.getItem("lang") == "ar";
      moment.locale(this.$i18n.locale == "en" ? "en" : "ar-kw");
    },
    generateUUID() {
      // Public Domain/MIT
      let d = new Date().getTime(); // Timestamp
      let d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; // Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        (c) => {
          let r = Math.random() * 16; // random number between 0 and 16
          if (d > 0) {
            // Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            // Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    login() {
      if (this.username && this.password) {
        this.loading = true;
        let randomid = this.$cookie.get("ClientId");
        if (!randomid) {
          randomid = this.generateUUID();
          this.$cookie.set("ClientId", randomid, 9999);
        }
        const data = {
          UserName: this.username.replace(/ /g, ""),
          Password: this.password,
          ClientId: randomid,
          ClientType: "Web",
        };
        axios
          .post("Account/Authenticate", data)
          .then((res) => {
            this.loading = false;
            // console.log(res.data.statusCode);
            if (res.data.statusCode == 1) {
              this.$store.dispatch("login", res.data.data);
            } else {
              Swal.fire({
                title: this.$t("login.TheUsernameOrPasswordIsIncorrect"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            Swal.fire({
              title: this.$t("login.TheUsernameOrPasswordIsIncorrect"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          });
      } else {
        Swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
  },
  created() {
    this.$store.dispatch("logout");
    // console.log(localStorage.getItem('lang'))
    // console.log(localStorage.getItem('darkMode'))
    // this.$vuetify.theme.dark=localStorage.getItem("darkMode")
    //       ? localStorage.getItem("darkMode") == "true"
    //         ? true
    //         : false
    //       : false;
    //     this.$vuetify.rtl = localStorage.getItem("lang") ? (localStorage.getItem("lang") == "ar" ? true : false) : true;
    //     this.$i18n.locale = this.$vuetify.rtl ;
  },
};
</script>
<style>
body,
html {
  height: 100%;
  background-repeat: no-repeat;
  /*background-image: linear-gradient(rgb(12, 97, 33),rgb(104, 145, 162));*/
  background: #eeeeee !important ;
  position: relative;
}

.cardClass {
  z-index: 10;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
<style scoped>
.row {
  margin: 0px !important;
}
</style>
